import React from 'react';
import Layout from '@components/Layout/Layout';
import TextHero from '@components/TextHero';
import { graphql } from 'gatsby';
import {
  ContentfulCard,
  ContentfulDialogContent,
  ContentfulTextList,
  StandardQuery,
} from '../../../graphql-types';

import SEO from '@components/seo';
import CardPackageInfo from '@components/Home/CardPackageInfo';
import Container from '@src/components/Container';
import Grid, { Row } from '@src/components/Grid';
import SmsDownloadForm from '@src/components/SmsDownloadForm/SmsDownloadForm';
import useContentfulImage from '@src/hooks/useContentfulImage.hook';
import Card, { CardImage } from '@src/components/Card';
import RockerTouchCard from '@src/components/RockerTouchCard';
import UspList, { UspListItem } from '@src/components/UspList/UspList';

import PrimaryButton from '@src/components/PrimaryButton/PrimaryButton';
import useIsMobile from '@src/hooks/useIsMobile.hook';
import { AppDownloadUrl } from '@src/pageConstants';
import * as ThirdPartyTracking from '../../lib/thirdPartyTracking';

import paketPages from '@src/data/paketPages';
import useDialogRoute from '@src/components/Dialog/useDialogRoute.hook';
import ContentfulDialog from '@src/components/Dialog/ContentfulDialog';

type StandardProps = {
  data: StandardQuery;
};

function Standard({ data }: StandardProps) {
  const isMobile = useIsMobile();

  const {
    contentfulPage: {
      headLine,
      pageHeader,
      pageHeaderDescription: { pageHeaderDescription },
      pageHeaderSubtitle: { pageHeaderSubtitle },
      pageContent,
      heroImage,
    },
  } = data;

  const cardImage = useContentfulImage(heroImage);
  const uspList = (pageContent[0] as ContentfulTextList).advancedTextListItems;

  const dlg2 = useDialogRoute('virtuelltkort');
  const dlg2Card = pageContent[2] as ContentfulCard;
  const dlg2Img = useContentfulImage(dlg2Card.backgroundImage);
  const dlg2Data = pageContent[3] as ContentfulDialogContent;

  const fixedCostsDialog = useDialogRoute('splitta');
  const fixedCostsCard = pageContent[4] as ContentfulCard;
  const dlg3Data = pageContent[5] as ContentfulDialogContent;
  const fixedCostsImg = useContentfulImage(fixedCostsCard.backgroundImage);

  const dlg4 = useDialogRoute('cashback');
  const dlg4Card = pageContent[6] as ContentfulCard;
  const dlg4Data = pageContent[7] as ContentfulDialogContent;
  const dlg4Image = useContentfulImage(dlg4Card.backgroundImage);

  const dlg5 = useDialogRoute('spara');
  const dlg5Card = pageContent[8] as ContentfulCard;
  const dlg5Data = pageContent[9] as ContentfulDialogContent;
  const savingsImg = useContentfulImage(dlg5Card.backgroundImage);

  return (
    <Layout theme="white" subNavLinks={paketPages}>
      <SEO title={headLine} />
      <Container style={{ marginTop: 52 }}>
        <Grid spacedRows>
          <TextHero
            theme="white"
            title={pageHeader}
            largeSubtitle
            subtitle={pageHeaderSubtitle}
            leftAligned
          >
            {pageHeaderDescription}
            <div style={{ marginTop: 32 }}>
              <UspList theme="purple" items={uspList as UspListItem[]} />
            </div>
            {isMobile && (
              <PrimaryButton
                style={{ marginTop: 32 }}
                href={AppDownloadUrl}
                onClick={ThirdPartyTracking.cardSignup}
              >
                Skaffa Standard
              </PrimaryButton>
            )}
          </TextHero>
          {!isMobile && (
            <Card centerContent backdropImage={cardImage} noBorder>
              <SmsDownloadForm
                formProps={{ redirectToAppstore: true }}
                title="Skaffa Rocker Standard idag"
              />
            </Card>
          )}
          <Card
            title={dlg2Card.title}
            text={dlg2Card.subtitle}
            cta={dlg2Card.linkText}
            onClick={dlg2.openDialog}
          >
            <CardImage imageData={dlg2Img} />
          </Card>
          <Card
            title={fixedCostsCard.title}
            text={fixedCostsCard.subtitle}
            cta={fixedCostsCard.linkText}
            onClick={fixedCostsDialog.openDialog}
            noBorder
            theme="purple"
            backdropImage={fixedCostsImg}
          ></Card>
          <Card
            title={dlg4Card.title}
            text={dlg4Card.subtitle}
            cta={dlg4Card.linkText}
            onClick={dlg4.openDialog}
            theme="dark"
            backdropImage={dlg4Image}
          />
          <Card
            title={dlg5Card.title}
            text={dlg5Card.subtitle}
            cta={dlg5Card.linkText}
            onClick={dlg5.openDialog}
            theme="soft"
            noBorder
          >
            <CardImage imageData={savingsImg} />
          </Card>
        </Grid>
      </Container>

      <CardPackageInfo />
      <ContentfulDialog
        visible={dlg2.isOpen}
        onClose={dlg2.closeDialog}
        contentfulData={dlg2Data}
      />
      <ContentfulDialog
        visible={fixedCostsDialog.isOpen}
        onClose={fixedCostsDialog.closeDialog}
        contentfulData={dlg3Data}
      />
      <ContentfulDialog
        visible={dlg4.isOpen}
        onClose={dlg4.closeDialog}
        contentfulData={dlg4Data}
      />
      <ContentfulDialog
        visible={dlg5.isOpen}
        onClose={dlg5.closeDialog}
        contentfulData={dlg5Data}
      />
    </Layout>
  );
}

export default Standard;

export const query = graphql`
  query Standard($locale: String) {
    contentfulPage(
      pageUrl: { eq: "/paket/standard" }
      node_locale: { eq: $locale }
    ) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
      }
      pageHeaderSubtitle {
        pageHeaderSubtitle
      }
      heroImage {
        title
        gatsbyImageData(width: 512)
      }
      pageContent {
        ... on ContentfulCard {
          id
          title
          subtitle
          backgroundImage {
            gatsbyImageData(width: 1040)
            title
          }
          linkText
          linkPath
        }
        ... on ContentfulCardsSectionComponent {
          id
          entryTitle
          card {
            title
            subtitle
            linkPath
            linkText
          }
        }
        ... on ContentfulFaqSection {
          id
          title
          paragraph {
            paragraph
          }
          questions {
            question
            title
            overline
            answer {
              answer
            }
          }
        }
        ... on ContentfulTextList {
          listName
          advancedTextListItems {
            icon
            text
          }
        }
        ... on ContentfulDialogContent {
          id
          name
          textContent {
            textContent
          }
          questions {
            answer {
              answer
            }
            question
          }
          heading
          headerImage {
            title
            gatsbyImageData(width: 630)
          }
          ctaHref
          ctaText
          extraContent {
            ... on ContentfulTextList {
              id
              listName
              advancedTextListItems {
                icon
                text
              }
            }
            ... on ContentfulTextSection {
              title
              content {
                content
              }
            }
            ... on ContentfulFaqSection {
              id
              questions {
                answer {
                  answer
                }
                question
              }
              title
              contactEmail
            }
          }
        }
      }
    }
  }
`;
